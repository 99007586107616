import { Alert } from "@mui/material";

export const Answers = ({ answers }) => {
    return (
        <div>
            {answers ? answers.map((a) => {
                return <Alert severity="success" style={{marginTop: "10px"}}>{a.text}</Alert>
            }) : ''}
        </div>
    )
}
