import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AnswerBox } from "./components/AnswerBox";
import { QuestionCard } from "./components/QuestionCard";
import { API_HOST } from "./constants";

export const GamePage = () => {
  const [question, setQuestion] = useState();
  useEffect(() => {
    fetch(`https://${API_HOST}/question/today`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setQuestion(json);
      });
  }, []);
  return (
    <Grid container marginTop="10%" textAlign="center">
      <Grid item md={4} xs={1}></Grid>
      <Grid item md={4} xs={10}>
        {question && <QuestionCard question={question} />}
        {question && <AnswerBox question_id={question.id} />}
      </Grid>
      <Grid item md={4} xs={1}></Grid>
    </Grid>
  );
};
