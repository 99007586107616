import { Button, Grid, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { API_HOST } from "../constants";
import { Answers } from './Answers';

function submitAnswer(answer, question_id, user_id) {
  fetch(`https://${API_HOST}/answers/`, {
    method: "POST",
    body: JSON.stringify({
      answer_text: answer,
      question_id,
      user_id,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    });
}

export function getLocalStorageValue(key) {
    const v = JSON.parse(localStorage.getItem(key));
    if (v?.expires < new Date().getTime()) {
        return v.value
    }
    return null
}

export const AnswerBox = ({ question_id }) => {
  const [answer, setAnswer] = useState();
  const [answered, setAnswered] = useState(getLocalStorageValue('answered') === true);
  const [question, setQuestion] = useState();

  const handleSubmitAnswer = () => {
    submitAnswer(answer, question_id, localStorage.getItem("user-id"));
    setAnswer("");
    setAnswered(true);
    localStorage.setItem('answered', JSON.stringify({value: true, expires: new Date().getTime() + 86400000 }));
  };

  useEffect(() => {
    fetch(`https://${API_HOST}/questions/${question_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setQuestion(json);
      });
  }, [answered, question_id]);

  function getLowerPortion() {
    if (answered) {
        return <Answers answers={question?.answers}></Answers>;
    }
    return (
        <>
        <TextField
          multiline
          rows={5}
          label="Answer"
          fullWidth
          onChange={(e) => setAnswer(e.target.value)}
        ></TextField>
        <Button
          variant="contained"
          style={{ marginTop: "5px" }}
          onClick={handleSubmitAnswer}
        >
          Let's Go
        </Button>
        </>
    )
  }

  return (
    <Grid container marginTop="5%">
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        {getLowerPortion()}
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};
