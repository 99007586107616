import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export const QuestionCard = ({ question }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5">{question.text}</Typography>
      </CardContent>
    </Card>
  );
};
