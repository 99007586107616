import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { API_HOST } from "./constants";

function submitName(name) {
  fetch(`https://${API_HOST}/users/`, {
    method: "POST",
    body: JSON.stringify({ name }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      localStorage.setItem("user-id", json.id);
      localStorage.setItem("user-name", name);
      window.location = "/game";
    });
}
export const LoginPage = () => {
  const [name, setName] = useState();

  if (localStorage.getItem("user-id") !== null) {
    window.location = "/game";
  }
  return (
    <Grid container marginTop="10%" textAlign="center">
      <Grid item xs={1} md={4}></Grid>
      <Grid item xs={10} md={4}>
        <TextField
          fullWidth={true}
          label="Name"
          onChange={(e) => setName(e.target.value)}
        ></TextField>
        <Button
          variant="contained"
          style={{ marginTop: "5px" }}
          onClick={() => submitName(name)}
        >
          Let's Go
        </Button>
      </Grid>
      <Grid item md={4} xs={1}></Grid>
    </Grid>
  );
};
